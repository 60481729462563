.poster-section {
    position: relative;
    width: 100%;
    height: 90vh;
    overflow: hidden;
    top: 73px;
}
  
  .poster-image {
    width: 100%;
    height: 100%;
    object-fit: fill; /* Ensures the image covers the full width and height */
  }
  
  .image-section {
    width: 100%;
    overflow: hidden;
    position: relative;
    top: 73px;
  }
  .additional-image {
    width: 100%;
    height: auto;
    display: block;
  
}
  



@media only screen and (max-width: 767px){

  .poster-section {
    position: relative;
    width: 100%;
    height: auto;
    overflow: hidden;
    top: 80px;
}
.additional-image {
  width: 100%;
  height: auto;
  display: block;
  object-fit: fill;
}
.image-section {
  width: 100%;
  overflow: hidden;
  position: relative;
  top: 78px;
}
.poster-image {
  width: 100%;
  height: auto;
  object-fit: fill;
}
}

@media only screen and (min-width: 768px) and (max-width: 1023px){
  .poster-section {
    position: relative;
    width: 100%;
    height: auto;
    overflow: hidden;
    top: 73px;
}
}