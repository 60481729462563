.footer {
    background-color: #0F412B;
    padding: 20px;
    text-align: center;
    font-size: 14px;
    color: #333;
    border-top: 1px solid #ddd;
    position: relative;
    top: 5rem;
  }
  
  .footer-links {
    list-style-type: none;
    padding: 0;
    margin: 0 0 10px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .footer-links li {
    margin: 0 15px;
  }
  
  .footer-links a {
    font-weight: 700;
    color: #ffffff;
    text-decoration: none;
    transition: color 0.3s;
    font-size: 22px;
  }
  
  .footer-links a:hover {
    color: #e3e3e3;
  }
  
  .footer-divider {
    border: none;
    border-top: 1px solid #ddd;
    margin: 20px 0;
  }
  
  .footer-rights {
    font-size: 16px;
    color: #bfbebe;
  }
  .link-info{
    text-decoration: none;
    color: #bfbebe;
  }
  .link-info:hover{
    color: white;
  }
  


  
@media only screen and (max-width: 767px){
  .footer-links a {
    font-weight: 700;
    color: #ffffff;
    text-decoration: none;
    transition: color 0.3s;
    font-size: 16px;
}

}

@media only screen and (min-width: 768px) and (max-width: 1023px){
  
}