.luxury-homes-section {
        position: relative;
        top: 5rem;
        padding: 2rem;
    }
  
  
  .container2 {
    max-width: 1200px;
    margin: 0 auto;
  }

  
  .section-heading {
    font-size: 3rem;
    margin-bottom: 20px;
    color:#ff6a00;
    font-weight: 700;
}
  
  .section-text {
    font-size: 1rem;
    line-height: 1.6;
    color: #555;
    margin-bottom: 15px;
  }
  



  
@media only screen and (max-width: 767px){
  .luxury-homes-section {
    position: relative;
    top: 5rem;
    padding: 1rem;
}
.section-text {
  font-size: 1rem;
  line-height: 1.6;
  color: #555;
  margin-bottom: 15px;
  text-align: justify;
}
.section-heading {
  font-size: 2rem;
  margin-bottom: 20px;
  color: #ff6a00;
  font-weight: 700;
  text-align: center;
}

}

@media only screen and (min-width: 768px) and (max-width: 1023px){
  .section-text {
    font-size: 1rem;
    line-height: 1.6;
    color: #555;
    margin-bottom: 15px;
    text-align: justify;
}
}