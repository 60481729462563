.cardd{
    padding-top: 3rem;
}
@media only screen and (max-width: 767px){


}

@media only screen and (min-width: 768px) and (max-width: 1023px){
  
}