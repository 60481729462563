.price-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem;
    position: relative;
    background-color: #ffffff;
    top: 5rem;
  }
  
  .left-p {
    flex: 0 0 40%;
    text-align: center;
  }
  
  .price-heading {
    font-size: 5rem;
    font-weight: bold;
    color: #000000;
    margin-bottom: 0.5rem;
}
  
  .price-subheading {
    font-size: 1.2rem;
    color: #000000;
  }
  .price-heading2 {
    font-size: 2.5rem;
    font-weight: bold;
    color: #000000;
    margin-bottom: 0.5rem;
}
  
  .price-subheading2 {
    font-size: 1.2rem;
    color: #000000;
  }
  
  
  .right-p {
    flex: 0 0 60%;
  }
  
  .price-list-heading {
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 1rem;
    text-align: left;
  }
  
  .price-table {
    width: 100%;
    border-collapse: collapse;
    background-color: #fff;
  }
  
  .price-table th,
  .price-table td {
    padding: 1rem;
    text-align: center;
    border: 1px solid #ddd;
  }
  
  .price-table th {
    background-color: #0f412b;
    color: #fff;
    font-weight: bold;
}
  
.price-table td {
  color: #131313;
  background-color: #f0ab7d;
}
  
  .enquire-link {
    color: #0f412b;
    cursor: pointer;
    font-weight: bold;
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .enquire-link:hover {
    color:#0a5837;
  }
  



  
@media only screen and (max-width: 767px){
  .price-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    position: relative;
    background-color: #ffffff;
    top: 5rem;
    flex-direction: column;
}
.price-list-heading {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 1rem;
  text-align: center;
}
.price-subheading {
  font-size: 1rem;
  color: #000000;
  margin-bottom: 1rem;
}
.price-heading {
  font-size: 3rem;
  font-weight: bold;
  color: #000000;
  margin-bottom: 0.5rem;
}
.price-table th, .price-table td {
  padding: 0.5rem;
  text-align: center;
  border: 1px solid #ddd;
  font-size: 10px;
}

}

@media only screen and (min-width: 768px) and (max-width: 1023px){
  .price-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    width: 100%;
    position: relative;
    background-color: #ffffff;
    top: 5rem;
}
.price-heading {
  font-size: 3.9rem;
  font-weight: bold;
  color: #000000;
  margin-bottom: 0.5rem;
}
.price-table th, .price-table td {
  padding: 0.5rem;
  text-align: center;
  border: 1px solid #ddd;
  font-size: 14px;
}
}