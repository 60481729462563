

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
 
}

.slider {
    height: 86vh;
    width: 100%;
    position: relative;
    margin: auto;
    top: 82px;
}

.slider .slide {
  display: none;
  height: 100%;
  width: 100%;
}

.slider .slide img {
    height: 100%;
    width: 100%;
    /* filter: contrast(90%); */
    object-fit: fill;
}

.slider .slide .caption {
  position: absolute;
  bottom: 88px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 22px;
  color: #ffffff;
  padding: 8px 16px;
  background-color: rgb(15 65 43 / 69%);
  border-radius: 4px;
}

.slider a.prev,
.slider a.next {
  position: absolute;
  top: 50%;
  font-size: 30px;
  cursor: pointer;
  user-select: none;
  color: #ffffff;
  padding: 12px;
  transition: 0.2s;
}

.slider a.prev:hover,
.slider a.next:hover {
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 3px;
}

.slider .next {
  right: 20px;
}

.slider .prev {
  left: 20px;
}

.show {
  display: block;
  animation: fade 0.5s ease-out;
}

@keyframes fade {
  from {
    opacity: 0.5;
  }
  to {
    opacity: 1;
  }
}




@media only screen and (max-width: 767px){
  .slider .slide .caption {
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 14px;
    color: #ffffff;
    padding: 8px 16px;
    background-color: rgb(15 65 43 / 69%);
    border-radius: 4px;
}
.slider {
  height: auto;
  width: 100%;
  position: relative;
  margin: auto;
  top: 82px;
}

}

@media only screen and (min-width: 768px) and (max-width: 1023px){
  
}