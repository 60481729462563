@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');


.full-gallery {
    position: relative;
    top: 5rem;
    background-color: black;
    padding: 2rem;
}

.gal-h {
  text-align: center;
  color: #ff6a00;
  padding: 2rem;
}
.container1 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 0;
    width: 100%;
    max-width: 100%;
    border: 5px solid rgb(255 106 0);
    border-radius: 5px;
    margin: 0 auto;
    padding: 5px 0;
}

.container > div {
  /* Style for all grid items */
  border: 1px solid black;
  text-align: center;
}

.container > div:nth-child(5) {
  /* Style for the middle column (row 2, column 2) */
  width: 100%;
  max-width: 640px;
}

.thumbnail, .thumbnails1, .thumbnails2, .thumbnails4 {
  padding: 10px;
  text-align: center;
}

.thumbnails1 {
  display: flex;
  justify-content: space-between;
}

.thumbnails1 img, .thumbnails2 img, .thumbnails4 img {
  width: 207px;
  height: 137px;
  cursor: pointer;
  opacity: 0.5;  
  transition: opacity 0.3s ease;
}

.thumbnails2 {
  display: grid;
  grid-template-columns: 1fr; /* Single column for thumbnails */
  gap: 10px;
}

.thumbnails4 {
  display: grid;
  justify-content: center;
}

.thumbnails1 img:hover, .thumbnails2 img:hover, .thumbnails4 img:hover {
  opacity: 1;
}
.ani-2 {
  width: 45rem;
  height: 28rem;
}
.ani-1 {
  animation: spin 0.4s linear;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.large-thumbnail {
  width: 100%;
  max-width: 640px;
  height: 437px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto; /* Center the large thumbnail */
}

.large-thumbnail img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

@media only screen and (max-width: 1200px) {
  .container {
    max-width: 580px;
  }

  .container > div:nth-child(5) {
    width: 100%;
    max-width: 340px;
  }

  .thumbnails1 img, .thumbnails2 img, .thumbnails4 img {
    width: 100px;
    height: 67px;
  }
}

@media only screen and (max-width: 768px) {
  .container {
    grid-template-columns: 1fr;
    max-width: 90%;
  }

  .container > div:nth-child(5) {
    width: 100%;
    max-width: 100%;
  }

  .thumbnails1 img, .thumbnails2 img, .thumbnails4 img {
    width: 90px;
    height: 60px;
  }

  .large-thumbnail {
    height: auto;
    max-width: 100%;
  }
}




@media only screen and (max-width: 767px){
  .full-gallery {
    position: relative;
    top: 5rem;
    background-color: black;
    padding: 1rem;
}
.thumbnails1 {
  display: flex;
  justify-content:space-between;
  gap: 5px;
}
.ani-2 {
  width: 100%;
  height: auto;
}
.container1 {
  display: flex;
  grid-template-columns: repeat(3, 1fr);
  gap: 0;
  width: 100%;
  max-width: 100%;
  border: 5px solid rgb(255 106 0);
  border-radius: 5px;
  margin: 0 auto;
  padding: 5px 0;
  flex-direction: column;
}
.thumbnails2 {
  display: none;
  grid-template-columns: 1fr;
  gap: 10px;
}
.thumbnails4 {
  display: flex;
  justify-content:space-between;
  justify-items: start;
  gap: 5px;
}

}

@media only screen and (min-width: 768px) and (max-width: 1023px){
  .thumbnails1 {
    display: flex;
    justify-content:space-between;
    gap: 5px;
  }
  .ani-2 {
    width: 100%;
    height: auto;
  }
  .container1 {
    display: flex;
    grid-template-columns: repeat(3, 1fr);
    gap: 0;
    width: 100%;
    max-width: 100%;
    border: 5px solid rgb(255 106 0);
    border-radius: 5px;
    margin: 0 auto;
    padding: 5px 0;
    flex-direction: column;
  }
  .thumbnails2 {
    display: none;
    grid-template-columns: 1fr;
    gap: 10px;
  }
  .thumbnails4 {
    display: flex;
    justify-content:space-between;
    justify-items: start;
    gap: 5px;
  }
  
}