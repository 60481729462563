
.chat-component {
  position: fixed;
  bottom: 5px;
  right: 12px;
  background-color:#0bbf41;
  border: 2px solid #ffffff;
  border-top-left-radius: 20px;
    border-bottom-right-radius: 20px;
  padding: 10px;
  transition: transform 0.3s ease-in;
  z-index: 4999;
  width: 3.5rem;
  overflow: hidden;
}
.chat-component2 {
  position: fixed;
  bottom: 69px;
  overflow: hidden;
  right: 12px;
  background-color: #4286f7;
  border: 2px solid #ffffff;
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
  padding: 10px;
  transition: transform 0.3s ease-in;
  z-index: 4999;
  width: 3.5rem;
}
  .icon-chat{
    width: 2rem;
    height: auto;
    color: #ffffff;
    
  }
  .chat-component:hover{
    transform: translateX(-7px); 
    transform-style: preserve-3d;
  }
  .chat-component2:hover {
    transform: translateX(-7px); 
    transform-style: preserve-3d;
}


  @media only screen and (max-width: 767px){
    .chat-component {
      position: fixed;
      bottom: 134px;
      background-color: #0bbf41;
      border: 2px solid #fdfdfd;
      border-radius: 28px;
      padding: 10px;
      transition: transform 0.3s ease-in;
      right: -17px;
  }
  .icon-chat{
    width: 1.5rem;
    height: auto;
    color: #ffffff;
  }
  .chat-component2 {
    position: fixed;
    bottom: 80px;
    right: -17px;
    background-color: #4286f7;
    border: 2px solid #fdfdfd;
    border-radius: 28px;
    padding: 10px;
    transition: transform 0.3s ease-in;
}
  }