.plans-section {
    display: flex;
    justify-content: space-between;
    /* padding: 20px; */
    background-color: #ffffff;
    position: relative;
    top: 5rem;
    padding: 4rem;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-bottom: 2rem;
}
  
  .plan-l, .plan-r {
    width: 48%;
  }
  
  .plan-l {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .plan-l h2 {
    font-size: 1.8rem;
    margin-bottom: 20px;
    font-weight: 700;
    color: #ff6a00;
  }
  
  .floor-plans-carousel .carousel-slide img {
    width: 100%;
    height: auto;
    border-radius: 8px;
  }
  
  .plan-r {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .floor-plans-carousel {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
    width: 88%;
}
  
.plan-r h2 {
    font-size: 1.8rem;
    margin-bottom: 20px;
    font-weight: 700;
    color: #ff6a00;
}
.slick-dots li.slick-active button:before {
  color: #0f412b !important;
}
  .master-plan-img {
    width: 100%;
    height: 29rem;
    border-radius: 8px;
}
  
  /* Additional styles for carousel dots */
  .slick-dots li button:before {
    color: #333;
    font-size: 10px;
  }
  
  .slick-dots li.slick-active button:before {
    color: #007bff;
  }
  

  
@media only screen and (max-width: 767px){
  .plans-section {
    display: flex;
    justify-content: space-between;
    /* padding: 20px; */
    background-color: #ffffff;
    position: relative;
    top: 5rem;
    padding: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 1rem;
    flex-direction: column;
    gap: 3rem;
}
.plan-l, .plan-r {
  width: 100%;
}

}

@media only screen and (min-width: 768px) and (max-width: 1023px){
  .plans-section {
    display: flex;
    justify-content: space-between;
    /* padding: 20px; */
    background-color: #ffffff;
    position: relative;
    top: 5rem;
    padding: 4rem;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-bottom: 2rem;
    flex-direction: column;
}
.plan-l, .plan-r {
  width: 100%;
}
}