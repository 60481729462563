@import url("https://fonts.googleapis.com/css2?family=Moderustic:wght@400&family=Playfair+Display:wght@600&display=swap");

* {
  margin: 0;
  padding: 0;
}

.boddy {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  position: relative;
  background: #ffffff;
  font-family: "Moderustic", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  top: 5rem;
  font-style: normal;
  padding-top: 2rem;
}

section {
  padding-block: min(20vh, 0.5rem);
  width: calc(min(70rem, 90%));
  margin-inline: auto;
  color: #111;
}

section h2 {
  letter-spacing: 0.025em;
  font-size: 2.6rem;
  line-height: 1.1;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
  color: #ff6a00;
}

section p {
  font-size: 1.1rem;
  color: #696969;
  margin-top: 0.8em;
  margin-left: 0.3em;
}

section a {
  display: inline-block;
  text-decoration: none;
}

section .containerr {
  margin-top: 4em;
  display: grid;
  gap: 2rem;
}

@media screen and (min-width: 40rem) {
  section .containerr {
    grid-template-columns: repeat(3, 1fr);
  }
}

section .containerr .card {
  padding: 0.625rem;
  background: #0e3c28;
  border-radius: 1.2rem;
  height: 16rem;
  overflow: hidden;
  cursor: pointer;
}
section .containerr .card2 {
  padding: 0.625rem;
  background: #0e3c28;
  border-radius: 1.2rem;
  height: 16rem;
  overflow: hidden;
  cursor: pointer;
}

section .containerr .card:nth-child(1) .box .icon .iconBox {
  background: #d3b19a;
}

section .containerr .card:nth-child(2) .box .icon .iconBox {
  background: #70b3b1;
}

section .containerr .card:nth-child(3) .box .icon .iconBox {
  background: #d05fa2;
}
section .containerr .card2:nth-child(1) .box .icon .iconBox {
  background: #d3b19a;
}

section .containerr .card2:nth-child(2) .box .icon .iconBox {
  background: #70b3b1;
}

section .containerr .card2:nth-child(3) .box .icon .iconBox {
  background: #d05fa2;
}

section .containerr .card-inner {
  position: relative;
  width: inherit;
  height: 12rem;
  background: var(--clr);
  border-bottom-right-radius: 0;
}

section .containerr .card-inner .box {
  width: 100%;
  height: 100%;
  background: #fff;
  border-radius: 1.6rem;
  overflow: hidden;
}

section .containerr .card-inner .box .imgBox {
  position: absolute;
  inset: 0;
  background-color: #0e3c28;
}

section .containerr .card-inner .box .imgBox img {
  width: 100%;
  height: 14rem;
  object-fit: fill;
  border-radius: 1rem;
}

@media screen and (min-width: 53rem) {
  section .containerr .card-inner .box img {
    border-radius: 1.6rem;
  }

  section .containerr .card-inner .icon {
    width: 18rem;
    border-top-left-radius: 1rem;
    right: -0.375rem;
  }

  section .containerr .card-inner .icon::before {
    position: absolute;
    content: "";
    bottom: 0.375rem;
    left: -1.25rem;
    background: transparent;
    width: 1.25rem;
    height: 1.25rem;
    border-bottom-right-radius: 1rem;
    box-shadow: 0.313rem 0.313rem 0 0.313rem #0e3c28;
  }

  section .containerr .card-inner .icon::after {
    position: absolute;
    content: "";
    top: -1.25rem;
    right: 0.04rem;
    background: transparent;
    width: 1.25rem;
    height: 1.25rem;
    border-bottom-right-radius: 1.25rem;
    box-shadow: 0.313rem 0.313rem 0 0.313rem #0e3c28;
  }
}

section .containerr .card-inner .box .icon {
  position: absolute;
  bottom: -0.375rem;
  right: 0;
  width: 90%;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #0e3c28;
  pointer-events: none;
  top: 182px;
}

section .containerr .card-inner .box .icon:hover .iconBox {
  transform: scale(1.1);
}

section .containerr .card-inner .box .icon h3 {
  margin-left: 0.3em;
  text-transform: uppercase;
  font-size: 0.9rem;
  color: #ffffff;
  font-weight: 400;
}

@media screen and (min-width: 53rem) {
  section .containerr .card-inner .box .icon h3 {
    margin-left: 1em;
  }
}

section .containerr .card-inner .box .icon .iconBox {
  position: absolute;
  inset: 0.625rem;
  background: #282828;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s;
}

section .containerr .card-inner .box .icon .iconBox span {
  color: #fff;
  font-size: 1.5rem;
}

section .containerr .card-inner .box .icon input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

section .containerr .card-inner .box .icon .checkmark {
  position: absolute;
  top: 0.938rem;
  right: 1.25rem;
  height: 1.063rem;
  width: 1.063rem;
  background-color: transparent;
  border: 0.063rem solid #c8c8c8;
  border-radius: 0.438rem;
}

section .containerr .card-inner .box .icon .checkmark::after {
  content: "";
  position: absolute;
  display: none;
  left: 0.375rem;
  top: 0.188rem;
  width: 0.188rem;
  height: 0.438rem;
  border: solid white;
  border-width: 0 0.125rem 0.125rem 0;
  transform: rotate(45deg);
}

section .containerr .card-inner .box .icon input:checked ~ .checkmark {
  background-color: #111;
}

section .containerr .card-inner .box .icon input:checked ~ .checkmark::after {
  display: block;
}

.card.active h3 {
  color: #ffffff !important;
  font-size: 2rem;
}

.card.active .checkmark {
  border: 0.063rem solid #111;
}

/* Overlay styling */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.overlay-content {
  position: relative;
}

.overlay img {
  max-width: 90vw;
  max-height: 90vh;
  object-fit: contain;
}

.overlay-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: rgb(0, 0, 0);
  font-size: 2rem;
  cursor: pointer;
}











@media only screen and (max-width: 767px){
  section .containerr .card-inner .box .icon {
    position: absolute;
    bottom: -0.375rem;
    right: 0;
    width: 80%;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #0e3c28;
    pointer-events: none;
    top: 182px;
    border-top-left-radius: 17px;
}

}

@media only screen and (min-width: 768px) and (max-width: 1023px){
  section .containerr {
    grid-template-columns: repeat(2, 1fr);
}
}









.boddy2 {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  position: relative;
  background: #ffffff;
  font-family: "Moderustic", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  top: 5rem;
  font-style: normal;
}

section {
  padding-block: min(20vh, 0.5rem);
  width: calc(min(70rem, 90%));
  margin-inline: auto;
  color: #111;
}

section h2 {
  letter-spacing: 0.025em;
  font-size: 2.6rem;
  line-height: 1.1;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
  color: #ff6a00;
}

section p {
  font-size: 1.1rem;
  color: #696969;
  margin-top: 0.8em;
  margin-left: 0.3em;
}

section a {
  display: inline-block;
  text-decoration: none;
}

section .containerr2 {
  margin-top: 4em;
  display: grid;
  gap: 2rem;
  justify-items: center;
}

@media screen and (min-width: 40rem) {
  section .containerr2 {
    grid-template-columns: repeat(1, 1fr);
  }
}

section .containerr .card {
  padding: 0.625rem;
  background: #0e3c28;
  border-radius: 1.2rem;
  height: 16rem;
  overflow: hidden;
  cursor: pointer;
}
section .containerr2 .card2 {
  padding: 0.625rem;
  background: #0e3c28;
  border-radius: 1.2rem;
  height: 23rem;
  width: 60%;
  overflow: hidden;
  cursor: pointer;
}

section .containerr .card:nth-child(1) .box .icon .iconBox {
  background: #d3b19a;
}

section .containerr .card:nth-child(2) .box .icon .iconBox {
  background: #70b3b1;
}

section .containerr .card:nth-child(3) .box .icon .iconBox {
  background: #d05fa2;
}
section .containerr .card2:nth-child(1) .box .icon .iconBox {
  background: #d3b19a;
}

section .containerr .card2:nth-child(2) .box .icon .iconBox {
  background: #70b3b1;
}

section .containerr .card2:nth-child(3) .box .icon .iconBox {
  background: #d05fa2;
}

section .containerr2 .card-inner2 {
  position: relative;
  width: 100%;
  height: 25rem;
  background: var(--clr);
  border-bottom-right-radius: 0;
}

section .containerr2 .card-inner2 .box2 {
  width: 100%;
  height: 100%;
  background: #fff;
  border-radius: 1.6rem;
  overflow: hidden;
}

section .containerr2 .card-inner2 .box2 .imgBox2 {
  position: absolute;
  inset: 0;
  background-color: #0e3c28;
}

section .containerr2 .card-inner2 .box2 .imgBox2 img {
  width: 100%;
  height: 21rem;
  object-fit: fill;
  border-radius: 1rem;
}

@media screen and (min-width: 53rem) {
  section .containerr2 .card-inner2 .box2 img {
    border-radius: 1.6rem;
  }

  section .containerr2 .card-inner2 .icon2 {
    width: 18rem;
    border-top-left-radius: 1rem;
    right: -0.375rem;
  }

  section .containerr2 .card-inner2 .icon2::before {
    position: absolute;
    content: "";
    bottom: 0.375rem;
    left: -1.25rem;
    background: transparent;
    width: 1.25rem;
    height: 1.25rem;
    border-bottom-right-radius: 1rem;
    box-shadow: 0.313rem 0.313rem 0 0.313rem #0e3c28;
  }

  section .containerr2 .card-inner2 .icon2::after {
    position: absolute;
    content: "";
    top: -1.25rem;
    right: 0.04rem;
    background: transparent;
    width: 1.25rem;
    height: 1.25rem;
    border-bottom-right-radius: 1.25rem;
    box-shadow: 0.313rem 0.313rem 0 0.313rem #0e3c28;
  }
}

section .containerr2 .card-inner2 .box2 .icon2 {
  position: absolute;
  bottom: -0.375rem;
  right: 0;
  width: 90%;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #0e3c28;
  pointer-events: none;
  top: 294px;
}

section .containerr2 .card-inner2 .box2 .icon2:hover .iconBox2 {
  transform: scale(1.1);
}

section .containerr2 .card-inner2 .box2 .icon2 h3 {
  margin-left: 0.3em;
  text-transform: uppercase;
  font-size: 1.5rem;
  color: #ffffff;
  font-weight: 400;
  margin-bottom: 0;
}

@media screen and (min-width: 53rem) {
  section .containerr2 .card-inner2 .box2 .icon2 h3 {
    margin-left: 1em;
  }
}

section .containerr2 .card-inner2 .box2 .icon2 .iconBox2 {
  position: absolute;
  inset: 0.625rem;
  background: #282828;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s;
}

section .containerr2 .card-inner2 .box2 .icon2 .iconBox2 span {
  color: #fff;
  font-size: 1.5rem;
}

section .containerr2 .card-inner2 .box2 .icon2 input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

section .containerr2 .card-inner2 .box2 .icon2 .checkmark {
  position: absolute;
  top: 0.938rem;
  right: 1.25rem;
  height: 1.063rem;
  width: 1.063rem;
  background-color: transparent;
  border: 0.063rem solid #c8c8c8;
  border-radius: 0.438rem;
}

section .containerr2 .card-inner2 .box2 .icon2 .checkmark::after {
  content: "";
  position: absolute;
  display: none;
  left: 0.375rem;
  top: 0.188rem;
  width: 0.188rem;
  height: 0.438rem;
  border: solid white;
  border-width: 0 0.125rem 0.125rem 0;
  transform: rotate(45deg);
}

section .containerr2 .card-inner2 .box2 .icon2 input:checked ~ .checkmark {
  background-color: #111;
}

section .containerr2 .card-inner2 .box2 .icon2 input:checked ~ .checkmark::after {
  display: block;
}

.card.active h3 {
  color: #ffffff !important;
  font-size: 2rem;
}

.card.active .checkmark {
  border: 0.063rem solid #111;
}

/* Overlay styling */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.overlay-content {
  position: relative;
}

.overlay img {
  max-width: 90vw;
  max-height: 90vh;
  object-fit: contain;
}

.overlay-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: rgb(0, 0, 0);
  font-size: 2rem;
  cursor: pointer;
}











@media only screen and (max-width: 767px){
  section .containerr2 .card-inner2 .box2 .icon2 {
    position: absolute;
    bottom: -0.375rem;
    right: 0;
    width: 80%;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #0e3c28;
    pointer-events: none;
    top: 292px;
    border-top-left-radius: 17px;
}
section .containerr2 .card-inner2 .box2 .icon2 h3 {
  margin-left: 0.3em;
  text-transform: uppercase;
  font-size: 1rem;
  color: #ffffff;
  font-weight: 400;
  margin-bottom: 0;
}
section .containerr2 .card2 {
  padding: 0.625rem;
  background: #0e3c28;
  border-radius: 1.2rem;
  height: 23rem;
  width: 100%;
  overflow: hidden;
  cursor: pointer;
}

}

@media only screen and (min-width: 768px) and (max-width: 1023px){
  section .containerr2 {
    grid-template-columns: repeat(1, 1fr);
}
section .containerr2 .card2 {
  padding: 0.625rem;
  background: #0e3c28;
  border-radius: 1.2rem;
  height: 23rem;
  width: 100%;
  overflow: hidden;
  cursor: pointer;
}
}
