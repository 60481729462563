.detaill{
    background-color: antiquewhite;
}
.poster-section2 {
    position: relative;
    width: 100%;
    height: 90vh;
    overflow: hidden;
    top: 73px;
}
.iconar{
    color: #ff6a00;
}
.section-heading2 {
    font-size: 3rem;
    margin-bottom: 35px;
    color: #ff6a00;
    font-weight: 700;
}
  
  .poster-image2 {
    width: 100%;
    height: 100%;
    object-fit: fill; /* Ensures the image covers the full width and height */
  }




  .about-section {
    padding: 40px;
    background-color: #f9f9f9;
  width: 100%;
    margin: 0 auto;
    position: relative;
    top: 75px;
  }
  

  
  .about-content {
    display: flex;
    justify-content: space-between;
  }
  
  .left-ab, .right-ab {
    width: 48%;
  }
  
  .about-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .about-list-item {
    font-size: 1.2rem;
    color: #555;
    margin: 15px 0;
    position: relative;
    padding-left: 35px;
    line-height: 1.6;
    font-family: 'Arial', sans-serif;
  }
  
  .about-icon {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    color: #ff6a00;
    font-size: 1.5rem;
  }
  
  .about-list-item:not(:last-child)::after {
    content: '';
    display: none;
    width: 2px;
    height: 20px;
    background-color: #ff6a00;
    position: absolute;
    left: 10px;
    bottom: -10px;
  }
  
  



@media only screen and (max-width: 767px){
    .about-section {
        padding: 10px;
        background-color: #f9f9f9;
        width: 100%;
        margin: 0 auto;
        position: relative;
        top: 75px;
    }
    .about-content {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
    }
    .left-ab, .right-ab {
        width: 100%;
    }
    .about-list-item {
        font-size: 0.8rem;
        color: #555;
        margin: 15px 0;
        position: relative;
        padding-left: 35px;
        line-height: 1.6;
        font-family: 'Arial', sans-serif;
    }
    .section-heading2 {
        font-size: 2rem;
        margin-bottom: 0px;
        color: #ff6a00;
        font-weight: 700;
        text-align: center;
    }

}

@media only screen and (min-width: 768px) and (max-width: 1023px){
  
}