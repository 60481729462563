/* Footer2.css */
.footer2 {
    background-color:#1c1c1c;
    padding: 20px;
    text-align: center;
    position: relative;
    border-top: 1px solid #e0e0e0;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .disclaimer-text {
    color: #ffffff;
    font-size: 14px;
    margin-bottom: 10px;
  }
  
  .footer-links {
    display: flex;
    justify-content: center;
    gap: 20px;
  }
  
  .footer-link {
    color: #fffbfb;
    text-decoration: none;
    font-size: 14px;
    transition: color 0.3s ease;
  }
  
  .footer-link:hover {
    color: #f41b1b;
  }
  
  @media (max-width: 768px) {
    .footer2 {
      padding: 15px;
    }
  
    .disclaimer-text {
      font-size: 13px;
    }
  
    .footer-link {
      font-size: 13px;
    }
  }
  